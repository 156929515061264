import React from 'react';
import {List, Typography} from "antd";
import {IconDownload} from "@tabler/icons-react";
import {saveAs} from "file-saver";
import {httpClient} from "../../api/http-client";
import {config} from "../../config";

const { Text } = Typography;

export type FileListProps = {
    files: string[];
    needHeader?: boolean;
};

export const FileList: React.VFC<FileListProps> = (props) => {
  return(
      <List
          itemLayout="horizontal"
          style={{border: "1px solid #f0f0f0"}}
          header={props.needHeader ? (<Text strong>Прикрепленные файлы:</Text>) : null}
          bordered
          dataSource={props.files}
          locale={{emptyText: "Нет файлов"}}
          renderItem={(item) => (
              <List.Item
                  actions={[
                    <a key="download-file"
                       style={{display: "flex", alignItems: "center"}}
                       rel="noreferrer"
                       onClick={() => {
                           httpClient.axios().get<Blob>(config.endPoints.getFile
                               .replace('{fullFileName}', item), {responseType: "blob"}).then((response) => {
                               saveAs(response.data, item.substring(item.lastIndexOf(':') + 4));
                           })
                       }}>
                      <IconDownload size={24} />
                    </a>
                  ]}
              >
                <Text italic>{item.substring(item.lastIndexOf(':') + 4)}</Text>
              </List.Item>
          )}
      />
  );
};
