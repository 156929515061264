import axiosStatic, { AxiosInstance } from "axios";

import { setupInterceptors } from "./http-client-interceptors";

type HttpClient = {
  axios: (baseURL?: string) => AxiosInstance;
};

const axiosInstance: AxiosInstance = axiosStatic.create();

const axios = (baseURL?: string) => {
  let instance: AxiosInstance = axiosInstance;

  if (baseURL && baseURL.length) {
    instance = axiosStatic.create({
      baseURL,
    });
    setupInterceptors(instance);
  }

  return instance;
};

setupInterceptors(axiosInstance);

export const httpClient: HttpClient = {
  axios,
};
