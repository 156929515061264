import React from "react";

import "./about-page.scss";
import {Section} from "../../shared/ui/section/section";
import {Card, Col, Flex, Image, Row, Typography} from "antd";
import authorImg1 from '../../media/authors/1.webp';
import authorImg2 from '../../media/authors/2.webp';
import authorImg3 from '../../media/authors/3.webp';
import authorImg4 from '../../media/authors/4.webp';
import authorImg5 from '../../media/authors/5.webp';
import authorImg6 from '../../media/authors/6.webp';
import {Footer} from "../../shared/ui/footer/footer";

const { Paragraph } = Typography;

type SocialLink = {
    socialTypeName: string;
    socialLink: string;
}

type AuthorInfo = {
    imageUrl: string;
    fullName: string;
    roleName: string;
    achievements: string[];
    about: string;
    socialLinks: SocialLink[];
};

export const AboutPage = (): JSX.Element => {

    const authorsData: AuthorInfo[] = [
        {
            imageUrl: authorImg1,
            fullName: "Бут Юлия Евгеньевна",
            roleName: "Руководитель проекта",
            achievements: [
                'Кандидат исторических наук, доцент кафедры новой и новейшей истории УГИ УрФУ.',
                'Автор 6 учебных пособий, 4 электронно-образовательных ресурсов, 40 научных публикаций, в т. ч. в журналах, индексируемых в международных базах данных Scopus, Web of Science, RSCI.',
                'Читает лекционные курсы и ведет семинарские занятия для студентов бакалавриата и магистратуры департамента «Исторический факультет» УрФУ.',
                'Стала победителем в Грантовом конкурсе для преподавателей магистратуры 2017–2018 гг. и разработала новый курс «Академическое письмо для историков» для обучающихся по программам магистратуры направления 46.04.01 «История» в сопровождении комплекса учебно-методических материалов. С 2018 г. читает учебный курс «Академическое письмо», включенный в основную часть учебных планов магистерских программ, реализуемых в департаменте «Исторический факультет» УрФУ по направлениям 46.06.01, 46.04.03, 46.04.02, 43.04.02, 43.04.01.',
                'Является автором учебного пособия «Академическое письмо для историков» (https://elibrary.ru/item.asp?id=41535630); одним из авторов электронно-образовательного ресурса «Академическое письмо» (https://elearn.urfu.ru/course/view.php?id=7032).',
                'Является автором учебного пособия «Академическое письмо для историков» (https://elibrary.ru/item.asp?id=41535630); одним из авторов электронно-образовательного ресурса «Академическое письмо» (https://elearn.urfu.ru/course/view.php?id=7032).',
            ],
            about: '',
            socialLinks: [
                {socialTypeName: "Страница на сайте УрФУ", socialLink: "https://urfu.ru/ru/about/personal-pages/personal/person/j.e.komleva/"},
                {socialTypeName: "Страница на сайте департамента «Исторический факультет»", socialLink: "https://hist-urgi.urfu.ru/ru/nash-fakultet/kafedry/kafedra-novoi-i-noveishei-istorii/sotrudniki-kafedry-novoi-i-noveishei-istorii/persons/content/4804"},
                {socialTypeName: "Страница на сайте Academia.edu", socialLink: "https://urfu.academia.edu/ЮлияБут"},
            ]
        },
        {
            imageUrl: authorImg2,
            fullName: "Бортников Владислав Игоревич",
            roleName: "Автор и редактор проекта",
            achievements: [
                'Кандидат филологических наук, доцент кафедры русского языка, общего языкознания и речевой коммуникации УГИ УрФУ.',
                'Автор 5 учебных пособий, более 90 научных публикаций, в т. ч. в журналах, индексируемых в международных базах данных Scopus, Web of Science, RSCI.',
                'Читает лекционные курсы и ведет семинарские занятия по академическому письму, стилистике русского языка, практике эффективной коммуникации для студентов бакалавриата и магистратуры департаментов «Филологический факультет», «Психологический факультет», лингвистики, международных отношений УГИ УрФУ.',
                'Входит в состав Уральской стилистической школы, где в 2015 г. под руководством проф. Т. В. Матвеевой защитил кандидатскую диссертацию с досрочным окончанием аспирантуры, в настоящее время работает над докторской. С 2016 г. включен в состав регионального жюри Всероссийской олимпиады школьников по русскому языку, а с 2024 г. вошел в состав и федерального жюри той же олимпиады.'
            ],
            about: '',
            socialLinks: [
                {socialTypeName: "Страница на сайте УрФУ", socialLink: "https://urfu.ru/ru/about/personal-pages/personal/person/vladislav.bortnikov/"},
                {socialTypeName: "Страница на elibrary.ru", socialLink: "https://elibrary.ru/author_items.asp?authorid=741491"}
            ]
        },
        {
            imageUrl: authorImg3,
            fullName: "Спиридонов Дмитрий Владимирович",
            roleName: "Автор и редактор проекта",
            achievements: [
                'Кандидат филологических наук, доцент кафедры германской филологии УГИ УрФУ.',
                'Автор учебно-методического пособия и коллективной монографии, 3 электронно-образовательных ресурсов, более 70 научных публикаций, в т. ч. в журналах, индексируемых в международных базах данных Scopus, Web of Science, RSCI.',
                'Читает лекционные курсы и ведет семинарские занятия для студентов бакалавриата и магистратуры департамента «Филологический факультет» УГИ УрФУ.',
                'Заместитель главного редактора журнала «Вопросы ономастики», член редколлегии журнала «Quaestio Rossica», член Ассоциации гуманитарных научных журналов.'
            ],
            about: '',
            socialLinks: [
                {socialTypeName: "Страница на сайте УрФУ", socialLink: "https://urfu.ru/ru/about/personal-pages/Personal/person/dmitry.spiridonov/"},
                {socialTypeName: "Страница в Academia.edu", socialLink: "https://urfu.academia.edu/DmitrySpiridonov"}
            ]
        },
        {
            imageUrl: authorImg4,
            fullName: "Баченина Валерия Вячеславовна",
            roleName: "Автор, ментор и менеджер разработки проекта",
            achievements: [
                'Аспирант кафедры новой и новейшей истории УГИ УрФУ.',
                'Автор более 15 научных работ, участник научных коллективов по реализации грантовых проектов, в т.ч. при поддержке Российского научного фонда и Фонда Владимира Потанина.',
                'Редактор и технический секретарь журнала «Imagines mundi: альманах исследований всеобщей истории XVI–XX вв.», серия «Германика».',
                'Участник международной программы студенческого обмена в университете Университет Востока (итал. Università degli Studi di Napoli «L\'Orientale»), г. Неаполь, Италия.',
                'Прошла курс в рамках ДПО УрФУ «Решение прикладных и фундаментальных задач в гуманитаристике с использованием языка программирования Python», являлась руководителем группы разработки цифрового механизма для анализа тональности комментариев пользователей социальных сетей.',
                'С 2023 г. работает системным аналитиком и менеджером проектов в компании коммерческой разработки.'
            ],
            about: '',
            socialLinks: []
        },
        {
            imageUrl: authorImg5,
            fullName: "Майоров Кирилл Владимирович",
            roleName: "Автор, ментор и аналитик проекта",
            achievements: [
                'Аспирант кафедры новой и новейшей истории УГИ УрФУ.',
                'Автор 8 научных работ в журналах, индексируемых в международных базах данных Scopus, Web of Science, RSCI и входящих в перечень ВАК.',
                'Победитель грантового конкурса УрФУ «Мой первый грант», руководитель проекта «Преобразование городского пространства имперских столиц в проектах проправительственных «аутсайдеров» Австро-Венгрии и Великобритании в XVIII–XIX вв.» (2023–2024 гг.).',
                'Принял участие в переводе с английского языка и редактировании третьей части «Приключений Робинзона Крузо» Д. Дефо.',
                'Является одним из редакторов и разработчиков мультимедийного контента для виртуального музея «Эпоха перемен. Интеллектуалы, власть и общество в Британии и России XVII–XVIII веков» в рамках гранта РНФ «Кризис ценностей и стратегии преодоления: идея “общего блага” в интеллектуальном дискурсе Британии и России (1650–1750)».',
                'С 2023 г. занимает должность аналитика в компании коммерческой разработки.'
            ],
            about: '',
            socialLinks: [
                {socialTypeName: 'Страница на научном портале УрФУ', socialLink: 'https://sciencedata.urfu.ru/portal/ru/persons/--(994d9bbe-b5dd-4742-bcfd-fe56a5e85321)/publications.html'},
                {socialTypeName: 'Страница на Semantic Scholar', socialLink: 'https://www.semanticscholar.org/author/%D0%9A.%D0%92.-%D0%9C%D0%B0%D0%B9%D0%BE%D1%80%D0%BE%D0%B2/2194474863'}
            ]
        },
        {
            imageUrl: authorImg6,
            fullName: 'Попович Алексей Игоревич',
            roleName: "Автор, ментор и редактор проекта",
            achievements: [
                'Ассистент и аспирант кафедры русской и зарубежной литературы, младший научный сотрудник лаборатории эдиционной археографии УГИ УрФУ.',
                'Автор более 30 научных публикаций, в т. ч. в журналах, индексируемых в международных базах данных Scopus, Web of Science, RSCI.',
                'Ведет семинарские занятия для студентов департаментов «Филологический факультет», «Исторический факультет», «Департамент искусствоведения, культурологии и дизайна» УГИ УрФУ.',
                'Редактор и технический секретарь международного научного журнала «Quaestio Rossica», координатор проекта «Издательство научных журналов УрФУ», ответственный редактор ряда научно-популярных изданий и интернет-порталов, участник научных коллективов по реализации грантовых проектов, в т. ч. при поддержке Российского научного фонда.'
            ],
            about: '',
            socialLinks: [
                {socialTypeName: 'Страница в Academia.edu', socialLink: 'https://urfu.academia.edu/AlexeyPopovich'},
                {socialTypeName: 'Telegram-канал', socialLink: 'https://t.me/russianmartyrs'}
            ]
        }
    ];

    const getAuthors = (): JSX.Element[] => {
        const result: JSX.Element[] = [];

        authorsData.forEach((author) => {
            result.push(
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <Card>
                        <Row gutter={[0, 26]}>
                            <Col xs={24} sm={24} md={24} lg={12} xl={10} xxl={12}>
                                <Flex style={{width: '100%', height: '100%'}} align="center" justify="center">
                                    <Image
                                        width={370}
                                        height={370}
                                        preview={false}
                                        src={author.imageUrl}
                                        style={{borderRadius: "50%", border: "solid 1px #f3f3f3"}}
                                    />
                                </Flex>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={14} xxl={12}>
                                <div style={{display: "flex", flexDirection: "column", justifyContent: "left",
                                    gap: "5px", textAlign: "left"}}>
                                    <Paragraph><strong>{author.fullName}</strong></Paragraph>
                                    <Paragraph>{author.roleName}</Paragraph>
                                    {author.achievements.length > 0 ? getAchievementsBlock(author.achievements) : null}
                                    <Paragraph>{author.about}</Paragraph>
                                    {author.socialLinks.map((link) =>
                                        (<a href={link.socialLink} target="_blank" rel="noreferrer">{link.socialTypeName}</a>))}
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            )
        })

        return result;
    }

    const getAchievementsBlock = (achievements: string[]) => {
        const resultList: JSX.Element[] = [];
        achievements.forEach((achievement) => {
            resultList.push(<li>{achievement}</li>);
        });
        return (<ul className="achievements">{resultList}</ul>)
    };

    return (
        <div className="about-page">
            <div style={{textAlign: "center"}}>
                <Paragraph style={{fontSize: "18pt"}}>Электронная лаборатория академического письма</Paragraph>
            </div>
            <Section title="Цель и миссия проекта">
                <Paragraph>
                    <strong>Цель ЭЛАП</strong> – обеспечить студентам, специализирующимся по гуманитарным наукам, возможность:
                </Paragraph>
                <Paragraph>
                    <ul>
                        <li>улучшить свои навыки академического письма при создании текстов разных жанров;</li>
                        <li>быстро найти информацию о правилах и нормах академического письма, принятых в российском и международном научных сообществах;</li>
                        <li>понять на конкретных примерах, как работают теоретические принципы академического письма;</li>
                        <li>быстро найти ответ на свой вопрос, связанный с академическим письмом, в максимально доступной форме;</li>
                        <li>тренировать навыки академического письма в удобное для себя время и в удобном темпе;</li>
                        <li>поработать с академическим инструктором-ментором, который поможет определить индивидуальные проблемы письма;</li>
                        <li>получить обратную связь от специалиста по академическому письму;</li>
                        <li>повысить мотивацию и преодолеть неуверенность при написании научных публикаций и заявок на научные конкурсы;</li>
                        <li>активизировать свое участие в научных мероприятиях;</li>
                        <li>повысить качество своей магистерской диссертации.</li>
                    </ul>
                </Paragraph>
                <Paragraph>
                    <strong>Миссия ЭЛАП</strong> – повышение уровня академической грамотности выпускников магистратур УГИ УрФУ.
                </Paragraph>
                <Paragraph>
                    Концепция академической грамотности – одна из ключевых концепций современного университета. Ее суть заключается в способности эффективно функционировать в мультилингвальной академической среде: осуществлять межкультурную коммуникацию посредством текстов разных жанров, критически мыслить, повышать самообразовательную компетентность в учебных и профессиональных целях, понимать и принимать ценности академического сообщества и нести ответственность за результаты своего исследования. Составляющими академической грамотности являются навыки критического чтения, академической устной и письменной коммуникации. Развитость всех компонентов академической грамотности, а именно умения мыслить критически, читать, писать, устно выступать и эффективно работать самостоятельно, напрямую влияет на успешность обучения в вузе и академический прогресс студентов.
                </Paragraph>
                <Paragraph>
                    К сожалению, в настоящее время студенты испытывают многочисленные трудности при создании собственных академических текстов. Их работы страдают разного рода структурными, стилистическими и логическими нарушениями, что влияет и на научную ценность исследования. Многие студенты не умеют ясно, емко, в научной форме изложить свои идеи, процесс формулирования собственных мыслей на письме отнимает у них много времени. Сохраняется проблема плагиата, неумения правильно использовать чужие идеи, уместно цитировать, корректно оформлять библиографические ссылки и списки. Особенные трудности испытывают магистранты, изменившие направление подготовки или специальность после окончания бакалавриата, а также иностранные студенты.
                </Paragraph>
                <Paragraph>
                    Задачу формирования у учащихся академической грамотности выполняет такая дисциплина, как «Академическое письмо», в рамках которой они осваивают российские и международные нормы создания академических текстов и развивают навыки написания собственных проблемно ориентированных исследовательских работ. Однако в виду ограниченного количества аудиторных часов, отведенных на курс, часто не удается в его рамках исчерпывающе осветить все вопросы, волнующие магистрантов. Остаются без ответа те вопросы, которые появляются у них после завершения курса в процессе обучения в магистратуре и подготовки ВКР.
                </Paragraph>
                <Paragraph>
                    Таким образом, встает острая проблема поддержки учащихся после завершения курса, когда они сталкиваются с необходимостью подготовить заявку на конкурс, научную публикацию, магистерскую диссертацию. Учебные пособия к курсу, помимо того, что их в целом крайне мало на русском языке, быстро устаревают, особенно те разделы, в которых описаны цифровые инструменты и онлайн-сервисы. В то же время очные консультации со специалистом по академическому письму не всегда доступны учащимся после завершения соответствующего курса. В этом плане ЭЛАП помогает преодолеть серьезную проблему, существующую, в частности, в УГИ УрФУ.
                </Paragraph>
                <Paragraph>
                    ЭЛАП – это эффективный инструментарий для развития навыков академического письма у гуманитариев. Она может использоваться как дополнительный сервис к учебным курсам по академического письму, так и как самостоятельный ресурс для выполнения отдельных задач, напрямую не связанных с отчетностью по учебному курсу (подготовка доклада на конференцию, написание научной статьи, подготовка текста магистерской диссертации и проч.).
                </Paragraph>
                <Paragraph>
                    <u>ЭЛАП функционирует по типу <i>Writing Labs</i> ведущих зарубежных университетов:</u>
                </Paragraph>
                <Paragraph>
                    <ul>
                        <li>предоставляет в открытом доступе авторские теоретические материалы, разработанные на высоком методологическом уровне, вносящие существенный вклад в развитие металингвистических и профессиональных компетенций магистрантов-гуманитариев;</li>
                        <li>предоставляет доступ к базе примеров из разных отраслей гуманитарных и социальных наук и банку практических заданий и упражнений для тренировки навыков академического письма;</li>
                        <li>обеспечивает самопроверку заданий (правильные ответы) и получение комментариев специалиста к выполненному заданию (в личном кабинете студента);</li>
                        <li>предоставляет возможность выявления индивидуальных проблем на письме и способов их устранения (работа с ментором).</li>
                    </ul>
                </Paragraph>
                <Paragraph>
                    <strong>Целевая аудитория ЭЛАП:</strong>
                </Paragraph>
                <Paragraph>
                    <ul>
                        <li>учащиеся магистратур по гуманитарным направлениям;</li>
                        <li>студенты бакалавриата по гуманитарным направлениям;</li>
                        <li>аспиранты и независимые исследователи;</li>
                        <li>учащиеся старших классов средних школ;</li>
                        <li>преподаватели, готовящие научные публикации в высокорейтинговых научных журналах.</li>
                    </ul>
                </Paragraph>
                <Paragraph>
                    По всем вопросам, связанным с работой сайта ЭЛАП, вы можете написать на почту <a href='mailto:e-lab.academic.writing@mail.ru' target='_blank' rel="noreferrer">e-lab.academic.writing@mail.ru</a>
                </Paragraph>
                {/*<div style={{width: "100%", height: "auto", display: "flex", justifyContent: "center", flexDirection: "row"}}>
                    <Image
                        width={600}
                        preview={false}
                        src="https://inbusiness.kz/uploads/34/images/EBZtk2RH.jpg"
                        style={{borderRadius: "32px", marginBottom: "10px"}}
                    />
                </div>*/}
            </Section>
            <br />
            <Section title="Команда проекта">
                <Row gutter={[16, 16]}>
                    {getAuthors()}
                </Row>
            </Section>
            <Footer />
        </div>
    )
};
